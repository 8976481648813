import React from "react";
export const API_BASE_URL = "https://backend.myaioreservations.com/";

const ApiUrl = () => {
  return (
    <div>
      <p>The API base URL is: {API_BASE_URL}</p>
    </div>
  );
};

export default ApiUrl;

import React from 'react';
// import SignupForm from '../components/frontend/signupForm.jsx';
import AdminRegisterForm from '../components/frontend/adminRegister.jsx';

function AdminSignup() {
  return(
    <div>
        <AdminRegisterForm />
    </div>
  );
}

export default AdminSignup;
